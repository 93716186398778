.scene{
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.scene-img{
  width: 30%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: spin-logo 50s infinite linear
}

@keyframes spin-logo{
  from{
    transform: rotateZ(20deg) perspective(5000px) rotateY(0); 
  }
  to{
    transform: rotateZ(20deg) perspective(5000px) rotateY(360deg);
  }
}
.scene-img-item{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-image: url(./Img/logo.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: .1;
}

.scene-img-item:nth-child(1){
  top:0px;
  transform: rotateY(0deg) translateZ(1000px);
}
.scene-img-item:nth-child(2){
  top:-200px;
  transform: rotateY(30deg) translateZ(1000px);
}
.scene-img-item:nth-child(3){
  top:500px;
  transform: rotateY(60deg) translateZ(1000px);
}
.scene-img-item:nth-child(4){
  top:0px;
  transform: rotateY(90deg)translateZ(1000px);
}
.scene-img-item:nth-child(5){
  top:-500px;
  transform: rotateY(120deg) translateZ(1000px);
}
.scene-img-item:nth-child(6){
  top:200px;
  transform: rotateY(180deg)translateZ(1000px);
}
.scene-img-item:nth-child(7){
  top:500px;
  transform: rotateY(210deg)translateZ(1000px);
}
.scene-img-item:nth-child(8){
  top:200px;
  transform: rotateY(240deg)translateZ(1000px);
}
.scene-img-item:nth-child(9){
  top:-300px;
  transform: rotateY(270deg)translateZ(1000px);
}
.scene-img-item:nth-child(10){
  top:100px;
  transform: rotateY(300deg)translateZ(1000px);
}
.scene-img-item:nth-child(11){
  top:500px;
  transform: rotateY(330deg)translateZ(1000px);
}

.wrap{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7em;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.logo{
  aspect-ratio: 1.5/1;
  height: 7em;
  background-image: url(./Img/logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0.6em;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.navigator{
  padding: 0 15em 0 15em;
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav{
  min-width:5em;
  text-align: center;
  text-decoration: none;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#1c3663;
  font-size: 1.3em;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  font-family: 'JalnanGothic';
}
.nav:hover{
  color:#E69147;
  font-weight: 900;
  cursor: pointer;
}

.nav:focus{
  color:#E69147;
  font-weight: 900;
  cursor: pointer;
}


.main{
  padding-top: 7em;
  padding-bottom: 10em;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: scroll;
}



.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5em;
  padding:  2.5em 0 ;
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.inner{
  position: relative;
  display: block;
  margin: 0 auto;
}
.footer p{
  margin: 0.5em 0;
}

.footer span{
  margin-right: 1em;
}

 @-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}



