.main-div1{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .main-div2{
    padding-right: 5%;
    padding-top: 5%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-family: 'JalnanGothic';
    -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .container{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
  }

  .container p{
    font-size: 1.1em;
    margin: 0.5em  0 ;
  }

  .container b{
    width: 10%;
    border-top: 3px solid #000;
    margin-bottom:1em;
  }

  .letter {
    font-size: 5em;
    display: inline-block;
    font-weight: bold;
    color: #1c3663;
    letter-spacing: 2px;
    position: relative;
    box-sizing: border-box;
  }
  .letter::after {
    content: '#즐거움';
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    text-shadow: 0 0 2px #1c3663, 0 0 1px #1c3663, 0 0 1px #1c3663;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 3s 6s linear forwards;
  }

  .letter1 {
    font-size: 4.5em;
    display: inline-block;
    font-weight: bold;
    color: #1c3663;
    letter-spacing: 2px;
    position: relative;
    box-sizing: border-box;
    
  }
  .letter1::after {
    content: '#호기심';
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    text-shadow: 0 0 2px #1c3663, 0 0 1px #1c3663, 0 0 1px #1c3663;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 3s 3s linear forwards;
  }

  .letter2 {
    font-size: 4em;
    display: inline-block;
    font-weight: bold;
    color: #1c3663;
    letter-spacing: 2px;
    position: relative;
    box-sizing: border-box;
  
  }
  .letter2::after {
    content: '#사람';
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    text-shadow: 0 0 2px #1c3663, 0 0 1px #1c3663, 0 0 1px #1c3663;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    animation: animloader 3s linear forwards ;
  }
  
  @keyframes animloader {
    0% {
      height: 100%;
    }
    100% {
      height: 0%;
    }
  }

 @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.div-section{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-behavior: smooth;
    
}

.box {
    position:relative;
    width:300px;
    aspect-ratio: 1/1;
    transform-style:preserve-3d;
    animation: cube 10s linear infinite;
  }
  


  @keyframes cube{
   100% {
    transform:  rotate3d(1,1,1,360deg);  
  }
  
}
  
  .box > div{
    width: 100%;
    height: 100%;
    position: absolute; 
    background-image: url(../Img/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    border: 1px solid #000;
  }


  .front{
    animation: front 5s linear infinite;
  }
  .back {
    animation: back 5s linear infinite;
  }
  .right{
    animation: right 5s linear infinite;
  }
  .left {
    animation: left 5s linear infinite;
  }
  .top{
    animation: top 5s linear infinite;
  }
  .bottom {
    animation: bottom 5s linear infinite;
  }

  @keyframes front {
    0%, 100% {
        transform: scale3d(1,1,1)  translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5)  translateZ(150px);
    }
  }
  @keyframes back {
    0%, 100% {
        transform: scale3d(1,1,1)  rotateY(180deg) translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5) rotateY(180deg) translateZ(150px);
    }
  }
  @keyframes right {
    0%, 100% {
        transform: scale3d(1,1,1)  rotateY(90deg) translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5)  rotateY(90deg) translateZ(150px);
    }
  }
  @keyframes left {
    0%, 100% {
        transform: scale3d(1,1,1)  rotateY(-90deg) translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5)  rotateY(-90deg) translateZ(150px);
    }
  }
  @keyframes top {
    0%, 100% {
        transform: scale3d(1,1,1)  rotateX(-90deg) translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5)  rotateX(-90deg) translateZ(150px);
    }
  }
  @keyframes bottom {
    0%, 100% {
        transform: scale3d(1,1,1)  rotateX(90deg) translateZ(150px);
    }
    50% {
         transform: scale3d(0.5,0.5,0.5)  rotateX(90deg) translateZ(150px);
    }
  }

.div-section1{
    padding: 1%;
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.div-section1 h1{
    width: 70%;
    font-size: 4em;
    font-family: 'JalnanGothic';
    margin: 0.5% 1%;
    color: #1c3663;
    letter-spacing: .1em;
}

.div-section1 h2{
    width: 70%;
    font-size: 1.5em;
    font-family: 'JalnanGothic';
    margin: 0.5% 1%;
    color: #1c3663;
    letter-spacing: .05em;
}

.div-section1 h3{
    width: 70%;
    font-size: 1.3em;
    font-family: 'JalnanGothic';
    margin:  0%;
    color: #1c3663;   
}


.div-section2{
    padding: 1%;
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-animation: slide-in-bottom 1s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.div-section2-grid{
  width: 70%;
  margin: 1% 0;
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(2,1fr)
}

.div-section2-grid h1{
  font-size: 2em;
  width: 100%;
  color: #1c3663;
  margin: 1% 0;
  font-weight: 500;
}
.div-section2-grid h2{
  font-size: 1em;
  color: #000;
  width: 90%;
  padding-left: 3%;
  margin: 0;
  font-weight: 100;
}

.div-section2-grid h3{
  font-size: 1.3em;
  color: #1c3663;
  width: 98%;
  margin: 0;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.div-section2-loop-box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.div-section2-btn-box{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-section2-btn-box button{
    width: 10em;
    aspect-ratio: 1/1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2em;
    box-shadow: 5px 5px 2px #c9c9c9;
    font-family: 'JalnanGothic';
}
.div-section2-btn-box button:focus,
.div-section2-btn-box button:active{
    width: 10em;
    aspect-ratio: 1/1;
    background-color: #1c3663;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2em;
    font-family: 'JalnanGothic';
    box-shadow: none;
    margin-top:5px ;
    margin-right:5px ;
}

.div-section2-btn-box button h2{
    padding-left: 5% ;
    width: 90%;
    color: #E69147;
    font-family: 'OTEnjoystoriesBA';
    font-size: 3em;
    text-align: left;
    margin: 1% 0;
}
.div-section2-btn-box button:focus h2,
.div-section2-btn-box button:active h2{
    display: none;
}
.div-section2-btn-box button h1{
    padding-left: 10% ;
    width: 90%;
    color: #1c3663;
    font-family: 'JalnanGothic';
    font-size: 1em;
    text-align: left;
    margin:1% 0;
}
.div-section2-btn-box button:focus h1,
.div-section2-btn-box button:active h1{
    width: 100%;
    color: #E69147;
    font-family: 'JalnanGothic';
    font-size: 1.5em;
    text-align: left;
    margin: 1% 0;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.div-section2-btn-box button p{
    display: none;
}
.div-section2-btn-box button:active p,
.div-section2-btn-box button:focus p{
    display: initial;
    color: #E69147;
    position: fixed;
    bottom: 10%;
    -webkit-animation: jello-vertical 1s both infinite;
    animation: jello-vertical 1s both infinite;
}

 @-webkit-keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1);
              transform: scale3d(0.85, 1.15, 1);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-vertical {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    40% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    50% {
      -webkit-transform: scale3d(0.85, 1.15, 1);
              transform: scale3d(0.85, 1.15, 1);
    }
    65% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    75% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
.div-section3-value{
    padding: 1%;
    width: 98%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #333;
    -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.div-section3-mission{
    padding: 1%;
    width: 98%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1c3663;
    -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.div-section3-vision{
    width: 100%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right,#e69147, orange );
    -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.div-section3-history{
    padding: 1%;
    width: 98%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FEC233;
    -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.div-section3-space{
    padding: 1%;
    width: 98%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.div-section3{
  padding: 1%;
  width: 98%;
  display: flex;   
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.div-section3-box{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1% 0;
}

.div-section3-nav{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:  1% 0;
}


.div-section3-nav-table{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.div-section3-nav select,
.div-section3-nav button{
  min-width: 8%;
  padding: 1%;
  border: none;
  border-radius: 1em;
  color: #000;  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 1.2em;
  font-family: 'JalnanGothic';
}


.div-section3-nav button:focus,
.div-section3-nav button:hover{
  min-width: 8%;
  padding: 1%;
  border: none;
  border-radius: 1em;
  background-color: #1c3663;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: bold;
  font-family: 'JalnanGothic';
}

.div-section3 table{
  width: 70%;
  height: 100%;
  background-color: #fff;
  border-collapse: collapse;
}

.div-section3 tr{
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
}

.div-section3  td{
  height: 50px;
  padding: 1%;
  text-align: center;
  font-size: 1em;
  color:rgb(110, 110, 110)
}

.div-section3 td span{
  margin: 0 0.5%;
  padding: 1%;
  text-align: center;
  font-weight: bold;
  border-radius: 3em;
  color: #FEC233;
}

.div-section3 ul{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 2% 0;
}

.div-section3 ul li{
  list-style-type: none;
  width: 3%;
  aspect-ratio: 1/1;
  transition: 0.6s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #676767
}
.div-section3 ul li:hover{
  font-weight: bolder;
  font-size: 1.3em;
  color: #000;
}

.activePage {
  font-weight: bolder;
  font-size: 1.3em;
  color: #000;
}

.div-section3-box-div{
    width: 50%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.div-section3-value h1{
    color:#fff;
    width: 70%;
    font-size: 3em;
    margin: 5% 0 0 0; 
}
.div-section3-value h3{
    color:#fff;
    width: 70%;
    font-size: 2.5em;
    margin: 1% 0;  
}

.div-section3-value h4{
    color:#fff;
    width: 100%;
    font-size: 1.2em;
    line-height: 1.7em;
    margin: 2% 0;
    text-decoration: underline
    
}

.div-section3-value h5{
    color:#999;
    width: 100%;
    font-size: 1em;
    word-break: keep-all;
    margin: 1% 0;
    line-height: 1.5em;
    font-weight: 100;
}

.div-section3-mission h1{
    color:#fff;
    width: 70%;
    font-size: 3em;
    margin: 5% 0 0 0; 
}
.div-section3-mission h3{
    color:#fff;
    width: 70%;
    font-size: 2.5em;
    font-family: 'JalnanGothic';
    margin: 1% 0;
    font-weight: bolder;
}

.div-section3-mission h4{
    color:#999;
    width: 100%;
    font-size: 1.2em;
    line-height: 1.7em;
    margin: 3% 0;
    font-weight: 100;
}

.div-section3-mission h5{
    color:#999;
    width: 100%;
    font-size: 1em;
    line-height: 1.5em;
    word-break: keep-all;
    margin: 0.5% 0;
    font-weight: 100;
}

.div-section3-box-div-visions div{
    width: 80%;
    height: 2px;
    background-color: #1c3663;
    border-radius: 3em;
    margin: 10% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.div-section3-box-div-visions div > span{
    position: relative;
    width: 2%;
    aspect-ratio: 1/1;
    background-color: #1c3663;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-section3-box-div-visions div > p{
    position: relative;
    width: 3%;
    aspect-ratio: 1/1;
    background-color: #1c3663;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-section3-box-div-visions div > b{
    position: relative;
    width: 15%;
    aspect-ratio: 1/1;
    color: #fff;
    background-color: #1c3663;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .8em;
}


.div-section3-box-div-visions-text{
    width: fit-content;
    height: fit-content;
    position: absolute;
    top:-200%;
    color:#e69147;
}


.div-section3-vision h1{
    color:#fff;
    width: 70%;
    font-size: 3em;
    margin: 5% 0 0 0; 
}
.div-section3-vision h2{
    color:#e69147;
    width: 80%;
    font-size: 3.5em;
    font-weight: bolder;
    text-align: left;
    display: flex;
}

.div-section3-vision h2 span{
    color:#e69147;
    width: fit-content;
    font-size: 0.4em;
    margin-right: 5%;
    font-weight: 100;
    text-align: left;
    display: flex;
    align-items:baseline;
    justify-content:baseline;
}

.div-section3-vision h3{
    color:#fff;
    width: 70%;
    font-size:2.5em;
    margin: 1% 0;
    font-weight: bolder;
}

.div-section3-vision h4{
    color:#e69147;
    padding: 0 5%;
    width: 90%;
    text-align: left;
    font-size: 2em;
    margin: 3% 0;
    font-weight: 900;
}

.div-section3-vision h5{
    color:#e69147;
    padding: 0 5%;
    width: 90%;
    text-align: left;
    font-size: 1.5em;
    word-break: keep-all;
    margin: 0.5% 0;
    font-weight: 100; 
}
.div-section3-box-div-visions{
    width: 50%;
    aspect-ratio: 1/1;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}


.div-section3-history h1{
    color:#000;
    width: 70%;
    font-size: 4em;
    margin: 5% 0 0 0; 
}
.div-section3-history h3{
    color:#000;
    width: 70%;
    font-size: 4em;
    margin: 1% 0 ;
    font-weight: bolder;
}

.div-section3-history div{
    width: 10px;
    height: 1600px;
    background-color: #000;
    border-radius: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.div-section3-history div > div{
  width: 1150px;
  height: 12.5%;
  background-color: transparent;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.div-section3-history div > div >p{
    width: 200px;
    color: #fff;
    background-color: #000;
    border-radius: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    padding: 15px;
}

.div-section3-history div > div > b {
    width: 100%;
    border: none;
    height: fit-content;
    display: flex;
    color: #000;
    line-height: 2em;
    font-size: 1.2em;
    font-weight: 100;
    
}


.div-section3-box-space{
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 2% 0;
}

.div-section3-box-div-space{
  width: 50%;
  display: flex;
  align-items: center;   
  flex-direction: column;
}



.div-section3-space h1{
  color:#000;
  width: 70%;
  font-size: 4em;
  margin: 10% 0 0 0; 
}
.div-section3-space h2{
  color:#000;
  width: 70%;
  font-size: 3em;
  margin: 1% 0;
  font-weight: bolder;
}

.div-section3-space h3{
  color:#000;
  width: 96%;
  font-size: 1em;
  font-weight: bolder;
}

.div-section3-space h4 span{
  background-color: #1c3663;
  color:#fff;
  width: fit-content;
  padding: 1% 1.5%;
  font-size: 1em;
  margin: 0.5% 2% ;
  border-radius: .3em;
}

.div-section3-space h4{
  color:#999;
  width: 100%;
  font-size: 1.3em;
  margin: 2% 0;
  font-weight: 100;
}

.div-section3-space h5{
  color:#999;
  width: 96%;
  font-size: 1.2em;
  margin: 2% 0.5%;
  font-weight: 100;
  word-break: keep-all;
  line-height: 1.7em;
}

.div-section3-space-img-0{
  width: 80%;
  aspect-ratio: 1/.56;
  background-image: url(../Img/pub-0.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
}
.div-section3-space-img-1{
  width: 80%;
  aspect-ratio: 1/.56;
  background-image: url(../Img/pub-1.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2%; 
}

.div-section3-space-img-2{
  width: 84%;
  aspect-ratio: 1/.56;
  background-image: url(../Img/santo-0.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
}
.div-section3-space-img-3{
  width: 80%;
  aspect-ratio: 1/.56;
  background-image: url(../Img/santo-1.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2%; 
}

.div-section3-box-div-vision{
    width: 70%;
    aspect-ratio: 1/1;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../Img/vision.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.div-section3-box-div-vision{
    width: 100%;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-sup{
    color: #fff;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-sup-icon{
    width: 30%;
    aspect-ratio: 1/1;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3%;
}

.box-sup-box{
    width: fit-content;
}

.box-sup-box h2{
    width: fit-content;
    font-size: 1.1em;
    margin: 1%;
}

.div-video{
    width: 45%;
    aspect-ratio: 1/.565;
}

.InfiniteLoop-slider { 
  width:100%;
  aspect-ratio: 1/.15;
  overflow-x: hidden;
}

.InfiniteLoop-inner { 
  display: flex;
  width: fit-content;
  height: 100%;
  animation: loop 30s linear infinite;
}
.InfiniteLoop-inner > *{ 
  flex-shrink: 1;
}
   
  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .InfiniteLoop-inner-item{
    position: relative;
    width: 6%;
    aspect-ratio: 1/1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 40px;
    box-shadow: 5px 5px 2px #c9c9c9;
    font-family: 'JalnanGothic';
    margin: 0.3%;
  }
  .InfiniteLoop-inner-item-img-0{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency0.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-1{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency1.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-2{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency2.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-3{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-image: url(../Img/Agency3.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-5{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency4.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-4{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency5.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-6{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency6.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-8{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency7.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-9{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency8.png);
    margin:1% 0;
  }
  .InfiniteLoop-inner-item-img-7{
    width: 70%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Agency9.png);
    margin:1% 0;
  }

  .InfiniteLoop-inner-item span{
    width: 85%;
    word-break: keep-all;
    font-size: .8em;
    text-align: center;
    margin:1% 0;
    line-height: 1.5em;
  }
  .InfiniteLoop-inner-item:hover{
    color:#FEC233;
  }

  .InfiniteLoop-inner-item:hover::before{
    width: 40%;
    aspect-ratio: 1/1;
    background-position:center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../Img/Icon.png);
    content: '';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -3%;
    right: -3%;
    -webkit-animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-bottom-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  }


@-webkit-keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}
@keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}

.div-section3-grid{
  margin: 2% 0;
  display: grid;
  gap: 10px;
  justify-content: center;
  grid-template-columns: repeat(4,300px);
  grid-template-rows: repeat(4,300px);
}

.div-section3-grid img{
  aspect-ratio: 1/1;
  height: 90%;
}

.div-section3-grid a{
  aspect-ratio: 1/1;
  height:300px;
  margin: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  box-shadow: 5px 5px 2px #c9c9c9;
  font-size: 1.4em;
  transition: 0.6s;
	-webkit-animation: scale-in-center 1s  cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 1s  cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.div-section3-grid a:active{
  aspect-ratio: 1/1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  box-shadow: none;
  font-size: 1em;
  margin-top:5px ;
  margin-right:5px ;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-22 16:26:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}


.div-section3-box-img{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(2,1fr);
  background-color: #ffffffa1;
  margin: 3% 0;
  gap: 10px;
}

.div-section3-box-img img{
  width: 100%;
  aspect-ratio: 1/1;
  box-shadow: 5px 5px 2px #c9c9c9;
  border-radius: 2em;
}
